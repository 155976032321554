@charset "utf-8";

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/NotoSansKR-Regular.woff2") format("woff2"),
        url("../fonts/NotoSansKR-Regular.woff") format("woff"),
        url("../fonts/NotoSansKR-Regular.otf") format("opentype");
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/NotoSansKR-Medium.woff2") format("woff2"),
        url("../fonts/NotoSansKR-Medium.woff") format("woff"),
        url("../fonts/NotoSansKR-Medium.otf") format("opentype");
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/NotoSansKR-Bold.woff2") format("woff2"),
        url("../fonts/NotoSansKR-Bold.woff") format("woff"),
        url("../fonts/NotoSansKR-Bold.otf") format("opentype");
}

body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,textarea,p,blockquote,th,td,input,select,textarea,button{margin:0;padding:0}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
html{height:100%;font-size:10px;overflow: hidden;}
body{min-width:320px;height:100%;-webkit-text-size-adjust:none;-moz-text-size-adjust:none;-ms-text-size-adjust:none;text-size-adjust:none}
body,th,td,input,select,textarea,button{font-family:'Noto Sans KR','Apple SD Gothic Neo',sans-serif;font-size:1.4rem;color:#282828;line-height:1;letter-spacing:-0.33px;}
fieldset,img{border:0 none}
img{vertical-align:top}
dl,ul,ol,menu,li{list-style:none}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}
input,select,textarea,button{font-size:100%;vertical-align:middle}
input[type='text'],input[type='password'],input[type='submit'],input[type='search']{-webkit-appearance:none;border-radius:0}
input:checked[type='checkbox']{background-color:#666;-webkit-appearance:checkbox}
button,input[type='button'],input[type='submit'],input[type='reset'],input[type='file']{-webkit-appearance:button;border-radius:0}
input[type='search']::-webkit-search-cancel-button{-webkit-appearance:none}
button{border:0 none;background-color:transparent;cursor:pointer}
table{border-collapse:collapse;border-spacing:0}
address,caption,cite,code,dfn,em,var{font-style:normal;font-weight:normal}
a{text-decoration:none}
a:active,a:hover{text-decoration:none}
mark{background-color:transparent}
.ico_comm{overflow:hidden;display:inline-block;background-size:contain;background-repeat:no-repeat;vertical-align:top;text-indent:-9999px}
/* placeholder 컬러 변경 */
input::-webkit-input-placeholder{color:#8e8e8e;}/* chrome, Edge */
input:-moz-placeholder{color:#8e8e8e;}/* firefox 4 t0 18 */
input::-moz-placeholder{color:#8e8e8e;}/* firefox 19+ */
input:-ms-input-placeholder{color:#8e8e8e;}/* IE 10, 11 */
input::-ms-input-placeholder{color:#8e8e8e;}/* Edge */
input::placeholder{color:#8e8e8e;opacity:1}/* opacity - 파이어폭스에서 뿌옇게 나오는 현상 방지*/
/* 포커스 시 placeholder 숨기기 - 필요에 따라 사용 */
input:focus::-webkit-input-placeholder{color:transparent}
input:focus:-moz-placeholder{color:transparent}
input:focus::-moz-placeholder{color:transparent}
input:focus:-ms-input-placeholder{color:transparent}
input:focus::-ms-input-placeholder{color:transparent}
input:focus::placeholder{color:transparent}
.screen_out{overflow:hidden;position:absolute;width:1px;height:1px;margin:-1px;padding:0;border:0;line-height:0;white-space:normal;word-wrap:break-word;word-break:break-all;clip:rect(0,0,0,0)}

/* common */
.inp_blind{position:absolute;top:auto;left:auto;width:0;height:0;opacity:0;cursor:pointer;border:0;outline:0;z-index:1}
.search_keyword{font-weight: 500;}

/* icon */
.ico_bot_logo{width:173px;height:34px;background-image:url(../images/ico_chat_logo.png);position:relative;left:40px;}
.ico_bot_logo_en{width:240px;height:35px;background-image:url(../images/ico_chat_logo_en.png);position:relative;left:40px;}
.ico_chatbot{
	width: 30px;
	height: 30px;
	background-image:url(../images/ico_chatbot.png);
	cursor: pointer;
}
.ico_menu{width:50px;height:50px;background-image:url(../images/ico_menu.png);}
.ico_send{width:54px;height:24px;background-image:url(../images/ico_send.png);}
.ico_close{width:24px;height: 24px;background-image:url(../images/ico_close.png);}
.ico_arrow{position:absolute;top:-9px;right:-9px;width: 46px;height: 46px;background-image:url(../images/ico_arrow_down.png);}
.ico_setting{display:inline-block;width:20px;height:20px;background:url(../images/ico_setting.png) 0 0 no-repeat;background-size: contain;vertical-align:top;margin-right:3px;}
.ico_info{display:inline-block;width:20px;height:20px;background:url(../images/ico_info.png) 0 0 no-repeat;background-size: contain;vertical-align: top;margin-right:3px;}
.ico_w_close{width:24px;height:24px;background-image:url(../images/ico_w_close.png);}

/* header */
.chat_header{
	position:relative;
	background-color: #0e4a84;
	z-index: 50;
}
.h_tit{padding: 11px 16px;height: 56px;text-align: left;box-sizing:border-box;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);}
.language_box{position: absolute;right: 16px;top: 16px;}
/*.btn_language{position: relative;color: #fff;width:78px;height: 30px;font-weight:500;padding: 5px 5px 5px 10px;border-radius: 15px;border:1px solid #fff;text-align:left;box-sizing:border-box;font-size: 1.5rem;background: #0e4a84;z-index: 100;}*/
.btn_language{color: #fff; font-weight: 500; line-height: 1.3;}
.ico_language{
	width: 24px;
	height : 24px;
	background-image : url(../images/ico_language.png);
}
.btn_language .ico_arrow{margin: 0 0 0 4px;}

.language_inner_box{
	display: none;
    position: fixed;
    top: 56px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(44,44,44,.1);
    z-index: 300;}
/*.language_list{position:absolute;top:-28px;right:16px;width:78px;height: 50px;background-color: #fff;box-sizing: border-box;border-radius: 0 0 10px 10px;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);overflow:hidden;}*/
.language_list{
	position: absolute;
    right: 0;
    top: 0;
    width: 97px;
    background-color: #fff;
    z-index: 101;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
}

.language_item+.language_item {
    border-top: 1px solid #d9d9d9;
}

/*.language_item .language_link{display:block;height: 50px;padding:22px 0 8px;color:#fff;box-sizing: border-box;color: #282828;line-height:1.5;font-weight: bold;text-align: center;}*/
.language_item .language_link{
	text-align: center;
	display: block;
    padding: 10px 20px;
    color: #fff;
    height: 40px;
    box-sizing: border-box;
    color: #0e4a84;
    line-height: 1.5;
    font-weight: 700;
}
.language_item.is_selected .language_link,.language_item .language_link:hover{background-color:#deeefe}
.btn_language.is_active .ico_arrow{background-image:url(../images/ico_arrow_up.png)}
.btn_language.is_active+.language_inner_box{display:block}

.btn_unit{position:absolute;top: 2px;left: 0;}
.sub_menu_box {
	/*display:none;*/
	position:fixed;
	top:0px;
	left:0;
	right:0;
	bottom:0;
	z-index:100;
	background-color: rgba(40, 40, 40, 0.3);
}
.sub_menu_inner {
	position: absolute;
	left: 0;
	top:0;
	bottom: 0;
	padding: 16px;
	width: 188px;
	background-color: #deeefe;
	box-sizing:border-box;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
	z-index: 500;
	display:flex;
	flex-direction: column;
    justify-content: space-between;
}
.profile_box{padding-top: 124px;text-align:center;}
.chat_logo_box{display:block;margin: 0 auto 10px;width:96px;height:96px;}
.chat_logo_box > img{max-width: 100%;}
.profile_name.login:before{content:"";display:inline-block;width:24px;height:24px;background:url(../images/ico_login.png) 0 0 no-repeat;background-size:contain;vertical-align: middle;position: relative;top: -3px;margin-right: 4px;}
.profile_name {
	font-size:1.6rem;
	font-weight:bold;
	letter-spacing:-0.38px;
	color : #000;
}

.sub_btn_group{margin-top:7px;}
.sub_btn_group:after{content:"";display:block;clear:both;}
.sub_btn_group.w_half .btn{width:calc(50% - 3px);}
.sub_btn_group .btn{float: left;width: 100%;height:40px;line-height: 20px;padding:9px 0 11px;background:#fff;border:1px solid #61b1ff;border-radius:8px;box-sizing:border-box;font-weight: bold;color: #61b1ff;}
.sub_btn_group .btn + .btn{margin-left:6px}
.sub_btn_group .btn.login{width : 48%;}
.sub_btn_group .btn.login.setting{width : 59%;}
.sub_btn_group .btn.login.tip{width : 37%;}

.sub_menu_list{position:absolute;bottom: 16px;left: 16px;right: 16px;}
.sub_menu_item{position:relative;text-align:center;}
.sub_menu_item + .sub_menu_item{margin-top:6px;}
.sub_menu_link {
	display:block;
	color: #61b1ff;
	padding: 10px 0;
	height: 40px;
	border-radius: 8px;
	border: 1px solid #61b1ff;
	box-sizing:border-box;
	line-height: 1.2;
	font-weight: bold;
	background: #fff;
	font-weight: bold;
	width: 100%;
}
.sub_menu_link:hover,.sub_menu_link.on{background: #61b1ff;color: #fff;}

.btn_close{position:absolute;top:8px; right : 13px;} 
.logout_link{display:inline-block;margin: 2px 0 0 4px;vertical-align:top;}
.logout_link:before{display:inline-block;width:16px;height:16px;background-image:url(../images/ico_logout.png);background-size:contain;vertical-align:top;content:'';}

.dimmed {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(44, 44, 44, 0.8);
	z-index: 1000;
}

.dimmed .guide {
	background: rgba(0, 0, 0, 0.6);
}
.layer_wrap.guide .layer_inner {
	width: auto;
	position: static;
	top: 0;
	left: 0;
	transform: none;
}
.layer_wrap.guide .layer_cont {
    background: transparent;
    padding: 50px 0 0 0;
    width: 312px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.layer_wrap.guide .layer_btn_group {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
}
.layer_wrap.guide .layer_btn_group > button {
    color: #f4f4f4;
    display: inline-block;
    height: 30px;
    padding: 5px 10px;
    border-radius: 18px;
    border: solid 2px#fff;
    box-sizing: border-box;
    font-weight: bold;
}
.layer_wrap.guide .layer_btn_group .btn_never_view {
    margin-right: 60px;
}

.layer_wrap.guide .layer_btn_group .btn_never_view_en {
    margin-right: 24px;
}

.layer_wrap.guide .layer_cont > img {
	max-height: 100%;
	max-width: 100%;
}
.layer_wrap {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	border-radius: 20px;
	z-index: 2000;
}
.layer_inner {
	overflow: hidden;
	width: 320px;
	vertical-align: middle;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 3000;
}
.layer_top{height:40px;padding: 8px 10px 8px 14px;border-radius:6px 6px 0 0;background-color: #0e4a84;box-sizing: border-box;}
.layer_h_tit{display:block;font-size: 1.6rem;color:#fff;font-weight: bold;text-align: left;}
.layer_h_tit:before{content:"";display:inline-block;width:20px;height:20px;background: url(../images/ico_layer_setting.png) left center no-repeat;background-size: contain;position: relative;top: 3px;margin-right: 6px;}
.layer_w_close{position:absolute;top:8px;right:10px;}
.layer_cont { 
	padding: 12px 20px 20px 20px;
	min-height: 364px;
	background-color:#fff;
	text-align:center;
	box-sizing: border-box;
	/*
	border-radius: 0 0 6px 6px;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
	*/
}
.message_set_item{position:relative;text-align:left;font-weight: 500;color:#fff;padding: 10px 0;box-sizing: border-box;line-height:1.3;}
.message_set_item + .message_set_item{border-top:1px solid #fff;}
.message_set_item:first-child{padding-top:0;}
.message_set_item:last-child{padding-bottom:0;}
.message_set_item .btn_control{right: 0;top: 16px;}
.message_set_item:first-child .btn_control{top : 6px;}

.tab_menu_box{height: 53px;border-radius: 5px;background: #fff;}
.tab_menu_list{width:100%;height:40px;box-sizing: border-box;}
.tab_menu_list:after{content:"";display:block;clear:both;}
.tab_menu_list .tab_menu_item{float: left;text-align:center;}
.tab_menu_list .tab_menu_item + .tab_menu_item{margin-left: 4px;}
.tab_menu_list .tab_menu_item:after{content:"";display:block;clear:both;}
.tab_menu_list .tab_menu_item .tab_menu_link{display:block;height: 40px;line-height:1.4;box-sizing: border-box;font-weight: bold;color: #b2b2b2;letter-spacing: -0.22px;padding: 10px 0;box-sizing: border-box;width: 138px;border-radius: 6px;background-color: #e3e3e3;}
.tab_menu_list .tab_menu_item .tab_menu_link.en{width : 280px;}
.tab_menu_list .tab_menu_item .tab_menu_link:hover span,.tab_menu_list .tab_menu_item .tab_menu_link:active,.tab_menu_list .tab_menu_item.active .tab_menu_link{background: #61b1ff;color:#fff;}

.tab_cont{display:none;}
.tab_cont .message_set{margin-top: 12px;border-radius: 6px;height: 280px;overflow-y: auto;background-color: #61b1ff;}
.tab_cont .message_set_list{margin:10px 20px;}
.tab_cont .message_set_list .btn_control.first{top: 6px;}
.tab_cont.active{display:block;}



/* content */
.chat_wrap{height:100%}
.chat_container{overflow-x:hidden;overflow-y:auto;position:absolute;left:0;top: 56px;right:0;bottom: 90px;background: #fff;}
.chat_content{padding: 20px 16px;height:100%;box-sizing:border-box;}
.chat_content:after{content:"";display:block;clear:both;}
.menual_area {
	/*
	overflow-y: hidden;
	overflow-x: hidden;
	*/
	/*
	padding-left: 7px;
	margin: 0 -15px 0 -7px;
	*/
	/*
	white-space: nowrap;
	*/
	margin: 0 0 0 36px;
}
.menual_area .bot_box {
	margin: 0;
	width: 260px;
	padding-bottom:0px;
}
.menual_area .bot_box .bot_txt {
	display: flex;
	flex-direction: column;
    justify-content: space-between;
	vertical-align: top;
	margin-bottom: 5px;
	width: 234px;
	min-width: 234px;
}
.bot_wrap,.user_wrap{margin-bottom:10px;}
.bot_wrap + .user_wrap, .bot_wrap + .bot_wrap{clear:both;}
.bot_wrap {
	position: relative;
	float: left;
	/*max-width: 85%;*/
	width:85%;
}
.bot_wrap .bot_flex {
	display: flex;
	flex-direction: column;
}
.bot_wrap:after{content:"";display:block;clear:both;}
.bot_wrap .bot_name{font-weight: bold;display: inline-block;margin: 6px 0 0 2px;}
.bot_box{margin: 0 0 0 36px; padding-bottom:5px;}
.bot_box .bot_txt {
	float:left;
	/*max-width:100%;*/
	background: #deeefe;
	padding: 10px;
	border-radius:10px;
	line-height:1.43;
	letter-spacing: 0;
	width: 95%;
    min-width: 240px;
	position: relative;
}
.bot_box .bot_txt.en{word-break:break-all;}
.bot_box .bot_txt.main:before{content:"";display:block;width: 130px;height: 130px;background:url(../images/ico_greeting.png) 0 0 no-repeat;background-size:contain;margin: 0 auto 5px auto;}
.bot_box .bot_txt + .bot_txt{clear:both;margin-top:6px;}


.bot_box .bot_txt + .bot_txt.ui_list{margin-top:10px;min-width: 254px;}
.bot_txt.ui_list{padding:10px;}
.bot_txt.ui_list .ui_top{background-color: #0e4a84;border-radius: 8px 8px 0 0;text-align:center;padding:6px 0;font-size:12px;font-weight: bold;color: #fff;border: 1px solid #0e4a84;margin: -10px -10px 10px -10px;}
.bot_txt.ui_list .ui_cont{padding:10px;}
.bot_txt.ui_list .ui_cont:after{content:"";display:block;clear:both;}
.bot_txt.ui_list .ui_cont .tit_box strong{letter-spacing:-0.28px;font-size: 12px;}
.bot_txt.ui_list .ui_cont .tit_box + .list_box{margin-top:10px;}
.bot_txt.ui_list .list_box li{position:relative}
.bot_txt.ui_list .list_box li:before{position:absolute;left:0;top:5px;width:6px;height:9px;background-image:url(../images/ico_more_red.png);background-size:contain;content:''}
.bot_txt.ui_list .list_box li:after{content:"";display:block;clear:both;}
.bot_txt.ui_list .list_box li + li{margin-top:10px}
.bot_txt.ui_list .list_box .list_tit_box{float:left;width: calc(100% - 60px);overflow: hidden;padding-left: 11px;}
.bot_txt.ui_list .list_box .img_box{float:right;width: 56px;height: 40px;border-radius: 4px;background-repeat: no-repeat;background-size: cover;background-position: 50% 50%;text-align: center;}
.bot_txt.ui_list .list_box .img_box > img{height:100%;}
.bot_txt.ui_list .list_box .list_tit_box strong{display:block;font-size: 14px;letter-spacing: -0.28px;white-space: nowrap;text-overflow: ellipsis;width: 100%;overflow: hidden; color:#282828;}
.bot_txt.ui_list .list_box .list_tit_box .sub_txt{letter-spacing: -0.33px;font-size: 12px; color:#282828;}

.bot_txt.ui_list .list_btn_group{margin-top:16px;}
.bot_txt.ui_list .list_btn_group.w_half .btn{float:left;width:50%;}
.bot_txt.ui_list .list_btn_group.w_half .btn + .btn{width:calc(50% - 6px);margin-left: 6px;}
.bot_txt.ui_list .list_btn_group .btn{width:100%;height:40px;padding:10px 0;border-radius: 8px;background-color:#fff;line-height: 1.43;font-weight: 500;}
.bot_txt.ui_list .list_btn_group .btn:hover, .bot_txt.ui_list .list_btn_group .btn:active,.bot_txt.ui_list .list_btn_group .btn.on{background:#fec011;}

.bot_txt.item .img_box{height: 102px;border-radius: 5px;background-repeat: no-repeat;background-size: cover;background-position: 50% 50%;}
.bot_txt.item .txt_area{margin-top:10px;height:160px;}

.btn_group{margin-top: 10px;}
.btn_group .btn_link {
	display:block;
	min-width: 225px;
	padding: 10px 8px;
	margin-bottom: 5px;
	min-height: 40px;
	border-radius: 8px;
	background-color: #fff;
	box-sizing:border-box;
	text-align:center;
	color: #282828;
	font-weight:500;
}
.btn_group .btn_link.en{font-size: 12px;letter-spacing:-0.26px;line-height:1.5;}
.btn_group.inner li + li{margin-top:6px;}
.btn_group .btn_link:hover,.btn_group .btn_link:active,.btn_group .btn_link.on{background-color:#fec011;}

.swiper-container.slide_wrap{overflow:visible;}
.slide_wrap{clear:both;padding: 6px 0 !important;}

/* 211210  query_list  css 추가 *//*
.query_list .slick-slide{margin-left:6px;}
.query_list .slick-slide:first-child{margin-left:0;}
.query_list .slick-list{overflow: visible;}
*/
.query_list {
	clear:both;
	padding-top: 6px;
	margin-left: 36px;
	overflow-x: auto;
	/*
	overflow-y:hidden;overflow-x:auto;margin:0 -15px 0 -5px;white-space:nowrap
	*/
}
.query_box {
	height:40px;
	padding: 10px 15px 10px 16px;
	margin-right:5px;
	background: #fff5da;
	border: 1px solid #fec011;
	border-radius: 8px;
	text-align: center;
	vertical-align: top;
	box-sizing: border-box;
} 
.query_box .word{line-height:20px;font-weight: 500;color: #282828;}
.query_box:hover,.query_box:active,.query_box.on{background: #fec011;}
.query_box.on .word,.query_box:hover .word,.query_box:active .word{color:#fff;font-weight:500;}
.time{clear:both;display:block;font-size:1.2rem;color: #8c8e90;padding: 4px 0 0 36px;}


.menu_box{margin-top: 11px;}
.menu_box .slick-dots{left:0;right:0;width:auto;}
.menu_box .slick-dots li{margin:-3px;}
.menu_box .slick-dots li button:before{font-size:30px;opacity:1;color: #fff;line-height:0;}
.menu_box .slick-dots li.slick-active button:before{color: #0e4a84;}

.chat_menu{margin: -10px 0 0 0;}
.chat_menu:after{content:"";display:block;clear:both;}
.chat_menu li{float:left;width: 33.33%;}
.chat_menu li.clear_fix{clear:both;}
.chat_menu .icon_box {
	display:flex;
	height:90px;
	margin: 10px 10px 0 0;
	padding: 8px 0 6px;
	background-color:#fff;
	box-sizing: border-box;
	border-radius:8px;
	flex-direction: column;
    justify-content: space-around;
}
.chat_menu .icon_box.two{padding-top:10px}
.icon_box .icon {
	display:block;
	width:40px;
	height:40px;
	background-repeat:no-repeat;
	background-size: contain !important;
	margin: 0 auto 6px auto;
}

.icon_box .icon.menu01{width:34px;background-image:url(../images/menu_ico01.png);}
.icon_box .icon.menu02{width:34px;background-image:url(../images/menu_ico02.png);}
.icon_box .icon.menu03{width:34px;background-image:url(../images/menu_ico03.png);}
.icon_box .icon.menu04{width:34px;background-image:url(../images/menu_ico04.png);}
.icon_box .icon.menu05{width:34px;background-image:url(../images/menu_ico05.png);}
.icon_box .icon.menu06{width:34px;background-image:url(../images/menu_ico06.png);}
.icon_box .icon.menu07{width:34px;background-image:url(../images/menu_ico07.png);}
.icon_box .icon.menu08{width:34px;background-image:url(../images/menu_ico08.png);}
.icon_box .icon.menu09{width:34px;background-image:url(../images/menu_ico09.png);}
.icon_box .icon.menu10{width:34px;background-image:url(../images/menu_ico10.png);}
.icon_box .icon.menu11{width:34px;background-image:url(../images/menu_ico11.png);}
.icon_box .icon.menu12{width:34px;background-image:url(../images/menu_ico12.png);}
.icon_box .icon.menu13{width:34px;background-image:url(../images/menu_ico13.png);}
.icon_box .icon.menu14{width:34px;background-image:url(../images/menu_ico14.png);}
.icon_box .icon.menu15{width:34px;background-image:url(../images/menu_ico15.png);}
.icon_box .icon.menu16{width:34px;background-image:url(../images/menu_ico16.png);}
.icon_box .icon.menu17{width:34px;background-image:url(../images/menu_ico17.png);}
.icon_box .icon.menu18{width:34px;background-image:url(../images/menu_ico18.png);}
 
.icon_box .m_txt{display:block;text-align:center;color:#282828;font-size:1.2rem; font-weight: 500;}
.icon_box:focus, .icon_box:hover, .icon_box:active,.icon_box.on{background:#fec011;}
.icon_box:focus .m_txt,.icon_box:hover .m_txt, .icon_box:active .m_txt, .icon_box.on .m_txt{font-weight:bold;}

.option_box{position:relative;clear: both;padding: 5px 0 0 36px;}
.option_box:after{content:"";display:block;clear:both;}
.btn_option{position:relative;width: calc(100% - 56px);min-width: 189px;height: 40px;padding: 10px;font-size:1.4rem;color: #8e8e8e;background-color:#fff;border: 1px solid #d9d9d9;border-radius: 10px;box-sizing: border-box;text-align: left;float: left;}
.btn_option .ico_arrow{background-image:url(../images/ico_arrow_down1.png);width: 20px;height: 20px;position: absolute;top: 10px;right: 10px;}
.option_inner_box{display:none;position:absolute;top:49px;left:36px;width: calc(100% - 94px);border: 1px solid #d9d9d9;border-radius: 0 0 8px 8px;background-color:#fff;z-index:10;overflow:hidden;}
.option_item .option_link{display:block;height: 40px;padding: 9px 10px 13px;color: #282828;box-sizing: border-box;line-height: 18px;}
.option_item + .option_item{border-top:1px solid #d9d9d9;}
.option_item.is_selected .option_link,.option_item .option_link:hover{background-color: #deeefe;}
.btn_option.is_active{border-radius: 8px 8px 0 0;}
.btn_option.is_active .ico_arrow{background-image:url(../images/ico_arrow_up1.png)}
.btn_option.is_active+.option_inner_box{display:block}
.btn_sel_ok{padding: 9px 11px 12px;font-size:1.4rem;background-color: #0e4a84;color:#fff;border-radius:10px;margin-left: 6px;float: right;width: 50px;height: 40px;box-sizing: border-box;}

.user_wrap:after{content:"";display:block;clear:both;}
.user_box .user_txt{max-width:88%;float: right;position:relative;padding:10px;background:#fec011;border-radius:10px 0 10px 10px;line-height:1.43;}
.user_box .user_txt.en{max-width: 75%;word-break:break-all;}
.user_box.arrow_none .user_txt:before{border-left:0;}
.user_box .user_txt + .user_txt{clear:both;}
.time.user{text-align:right;}


/* bottom */
.chat_bottom{position:absolute;left:0;right:0;bottom:0;z-index: 10;}
.chat_bottom .inp_box{padding: 9px 10px;background-color: #d9d9d9;}
.inp_txt{position:relative;height: 38px;}
.inp_txt .inp{display:block;padding: 0 65px 0 10px;width:100%;height:100%;background-color:#fff;border:0;border-radius: 5px;box-sizing:border-box;}
.btn_send{position:absolute;right:0;top:0;padding: 7px 6px 7px 0;}
.inp_txt .inp:focus+.btn_send .ico_send,.btn_send.is_active .ico_send{background-image:url(../images/ico_send_on.png)}

.qna_box{display:none;}
.qna_item .qna_link {
	display: block;
	padding: 8px 20px;
	height: 36px;
	font-size: 1.4rem;
	color: #282828;
	line-height: 1.4;
	text-align: left;
	background-color: #fff;
	box-sizing: border-box;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	width: 100%;
	scroll-snap-align: start;
}
.qna_item .qna_link .num{display:inline-block;margin-right:6px;}
.qna_item .qna_link:hover,.qna_item.is_selected .qna_link{background-color: #f5f5f5;}

.qna_item .btn_delete{position:absolute;right:10px;top:50%;padding:5px;margin-top:-12px;z-index:10}
.ly_box_top{text-align:right;height:36px;padding-right:20px;position:relative;z-index:100;padding: 10px 15px 9px;box-sizing: border-box;background-color: #d9d9d9;line-height: 1.3;}
.ly_box_tit{display:inline-block;vertical-align:top;font-size:1.2rem;color:#282828;font-weight:500;line-height:1.4;}
.ly_box_bottom{border-radius: 10px 10px 0 0;background: #da2127;}
.ly_box_bottom.auto{display:none;background:#fff;border-radius:0;box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 #d9d9d9;}
.ly_box_bottom .btn_control{position:relative;right:auto;top:auto;margin-left: 4px;}
.auto_box{display:none;overflow-x:hidden;overflow-y:auto;max-height:108px;}
.ly_box_bottom.is_open .auto_box{display:block}

.btn_control{position:absolute;right: 15px;top:50%;padding:0 6px 1px 18px;margin-top: -5px;width: 32px;height: 17px;font-size:1.2rem;color:#666;background-color: #fff;border-radius: 11px;box-sizing:border-box;background: #fec011;}
.btn_control:before{position:absolute;left: 1px;top: 1px;width:15px;height:15px;border-radius: 10px;background-color: #fff;content:'';box-shadow: 2px 1px 4px -2px rgba(52, 51, 52, 0.2);}
.btn_control.is_active{display:inline-block;padding:0 18px 1px 6px;color:#fff;background: #fff;}
.btn_control.is_active:before{left:auto;right: 1px;background: #fec011;}

/* media */
@media (min-width: 501px) {
	.chat_menu .icon_box {
		border-radius: 36px;
	}
}

@media (max-width: 320px) {
	.layer_wrap.guide .layer_cont > img {
		max-width: 80%;
	}
}

@media (max-height: 480px) {
	.layer_wrap.guide .layer_cont > img {
		max-width: 60%;
	}
	/*
	.chat_logo_box {
		display: block;
		margin: 0 auto 10px;
		width: 80px;
		height: 80px;
	}
	*/
}
/* loading */
.three-balls {
	margin: 10px auto 0;
	width: 60px;
	text-align: center;
}
.three-balls .ball {
	margin-right: 1px;
	position: relative;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	display: inline-block;
	-webkit-animation: bouncedelay 3s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
	animation: bouncedelay 3s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
	background: #adc5fa;
}
.three-balls .ball1 {
	margin-right: 1px;
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}
.three-balls .ball2 {
	-webkit-animation-delay: -0.08s;
	animation-delay: -0.08s;
}
@keyframes bouncedelay {
	0% {
		bottom: 0;
		background-color: #adc5fa;
	}
	16.66% {
		bottom: 12px;
		background-color: #739df7;
	}
	33.33% {
		bottom: -5px;
		background-color: #739df7;
	}
	50% {
		bottom: 12px;
		background-color: #0e4a84;
	}
	66.66% {
		bottom: -5px;
		background-color: #0e4a84;
	}
	83.33% {
		bottom: 12px;
		background-color: #adc5fa;
	}
	100% {
		bottom: -5px;
		background-color: #adc5fa;
	}
}
@-webkit-keyframes bouncedelay {
	0% {
		bottom: 0;
		background-color: #adc5fa;
	}
	16.66% {
		bottom: 12px;
		background-color: #739df7;
	}
	33.33% {
		bottom: -5px;
		background-color: #739df7;
	}
	50% {
		bottom: 12px;
		background-color: #0e4a84;
	}
	66.66% {
		bottom: -5px;
		background-color: #0e4a84;
	}
	83.33% {
		bottom: 12px;
		background-color: #adc5fa;
	}
	100% {
		bottom: -5px;
		background-color: #adc5fa;
	}
}

.pagination ul {
	display: block;
	padding: 5px 0 0 0;
	margin: 0;
	text-align: center;
}
.pagination li {
	display: inline-block;
	background: #adc5fa;
	list-style: none;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin: 5px 3px 5px 3px;
	transition: background-color ease 0.3s;
	cursor: pointer;
}
.pagination li.checked {
	background: #0e4a84;
}
